import React from "react"
import styled from "styled-components"

const StyledButton = styled.button`
  padding: 0.5rem 1.5rem;
  border-radius: 16px;
  background: #6d99fd;
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
  box-shadow: 0px 5px 8px rgba(12, 22, 62, 0.06);
  outline: none !important;
  outline-offset: none !important;
  cursor: pointer;
  border: none;
  opacity: ${props => (props.disabled ? 0.4 : 1.0)};
`

const Button = props => {
  return (
    <StyledButton
      onClick={() => {
        if (props.onClick) {
          props.onClick()
        }
      }}
      {...props}
    >
      {props.text}
      {props.children}
    </StyledButton>
  )
}

export default Button
