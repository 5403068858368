import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  border-radius: 8px;
  background: #ffffff;
  padding-left: 16px;
  height: 48px;
  border: none;
  ::-webkit-input-placeholder {
    color: rgba(165, 167, 177, 0.5)
    transform: translate3d(0, 1px, 0);
  }
  outline: none !important;
  outline-offset: none !important;
  :disabled {
    opacity: 0.2;
  }

  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #1e1e1e;  

  border: 1px solid #EDF2F7
`;

const InputField = (props) => {
  return (
    <StyledInput
      type={props.type ?? 'text'}
      value={props.value}
      pattern={props.pattern}
      maxLength={props.maxLength}
      placeholder={props.placeholderText}
      onChange={(event) => props.onChange(event.target.value)}
      onBlur={props.onBlur}
      style={{...props.style}}
      disabled={props.disabled}
    />
  );
};

export default InputField;
