import React, { useState } from "react"
import styled from "styled-components"

import InputField from "../components/inputfield"
import Button from "../components/button"
import Layout from "../components/layout"
import SEO from "../components/seo"
import vincitLogo from "../images/Vincit_logo_red_SPOT.svg"

const BASE_URL = "https://tranquil-escarpment-08693.herokuapp.com"

const HStack = styled.div`
  display: flex;
  flex-direction: row;
`

const VStack = styled.div`
  display: flex;
  flex-direction: column;
`

const SmileBellLogo = styled.h1`
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 31px;
  color: #6d99fd;
`

const By = styled.h2`
  font-family: proxima-nova, sans-serif;
  font-style: italic;
  font-size: 15px;
  line-height: 15px;
  color: #a3b2bb;
`

const Heading1 = styled.h1`
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: calc(2.2em + 0.5vmin);
  line-height: 117.5%;
  text-align: center;
  color: #393e41;
`

const Heading3 = styled.h3`
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: calc(1.2em + 0.5vmin);
  line-height: 117.5%;
  text-align: center;
  color: #6d99fd;
`

const Subheader = styled.h3`
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  /* or 25px */
  text-align: center;
  color: #777777;
`

const IndexPage = () => {
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [requestSent, setRequestSent] = useState(false)

  const sendForm = async () => {
    const url = `${BASE_URL}/requestdemo?phone=${phone}&email=${email}`
    fetch(url, {
      method: "POST",
    })
  }

  const renderForm = () => {
    if (requestSent) {
      return (
        <VStack
          style={{
            margin: "1rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Heading3>Request sent - talk to you soon!</Heading3>
        </VStack>
      )
    }
    return (
      <VStack style={{ margin: "1rem", justifyContent: "center" }}>
        <InputField
          style={{ marginLeft: "32px" }}
          value={phone}
          placeholderText="Phone number"
          onChange={text => {
            setPhone(text)
          }}
        />
        <InputField
          style={{ marginLeft: "32px", marginTop: "0.5rem" }}
          value={email}
          placeholderText="Email address"
          onChange={text => {
            setEmail(text)
          }}
        />
        <div style={{ alignSelf: "center", marginTop: "1rem" }}>
          <Button
            onClick={() => {
              if (phone.length > 0 || email.length > 0) {
                sendForm()
                setRequestSent(true)
              }
            }}
            text="Send request"
          />
        </div>
      </VStack>
    )
  }

  return (
    <Layout>
      <SEO title="Get in touch" />
      <VStack>
        <VStack
          style={{
            alignSelf: "center",
            minHeight: "100vh",
            padding: "64px 32px 0px 32px",
            justifyItems: "space-around",
            justifyContent: "space-around",
          }}
        >
          <VStack
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HStack>
              <SmileBellLogo>SmileBell®</SmileBellLogo>
              <By style={{ padding: "8px" }}>by</By>
              <div>
                <img
                  src={vincitLogo}
                  alt="Vincit"
                  style={{ padding: "4px 0 0 0" }}
                />
              </div>
            </HStack>
          </VStack>
          <div>
            <Heading1>Get in touch </Heading1>
            <Subheader style={{ marginTop: "1rem" }}>
              We'll be happy to evaluate the suitability of Smilebell for your
              office.
            </Subheader>
            <Subheader style={{ marginTop: "1rem" }}>
              Simply leave your preferred contact detail below and we'll be in
              touch in no time.
            </Subheader>
            {renderForm()}
          </div>
          <div />
        </VStack>
      </VStack>
    </Layout>
  )
}

export default IndexPage
